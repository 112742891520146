<template>
	<div class="right-tool">
		<div class="tool-wrap flex-col">
			<div class="code-wrap btn">
				<div class="code-box">
					<img src="../../assets/images/code.png" />
					<div class="text flex-col">
						<p>扫描二维码</p>
						<p>关注微信公众号</p>
					</div>
				</div>
			</div>
			<div class="to-top btn" @click="topTap">
			</div>
		</div>
	</div>
</template>

<script>
	import $ from 'jquery'
	export default{
		props:{
			isEmit: {
				type: Boolean,
				default: false
			}
		},
		data(){
			return{}
		},
		methods: {
			topTap() {
				if (this.isEmit) {
					this.$emit('topTap')
				} else {
					$('html,body').animate({scrollTop: 0},500)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.right-tool{
		position: fixed;
		right: 1vw;
		bottom: 20vh;
		z-index: 102;
		opacity: 0;
		transition: all .3s linear;
		&.show{
			opacity: 1;
		}
		.tool-wrap{
			.btn{
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background:rgba(173, 173, 173, .25);
				background-size: contain;
				cursor: pointer;
				&.to-top{
					margin-top: 2vh;
					background-image: url(../../assets/images/top_icon.png);
					background-repeat: no-repeat;
					background-size: 15px 10px;
					background-position: center;
				}
				&.code-wrap{
					position: relative;
					background-image: url(../../assets/images/code_icon.png);
					background-repeat: no-repeat;
					background-size: 15px 15px;
					background-position: center;
					&:hover{
						.code-box{
							opacity: 1;
							transform: none;
							visibility: visible;
						}
					}
				}
				.code-box{
					position: absolute;
					visibility: hidden;
					left: -144px;
					top: 0;
					opacity: 0;
					padding: 20px;
					background-color: RGBA(55, 55, 55, 1);
					border-radius: 2px;
					transform: translateX(20px);
					transition: all 0.3s ease-in-out;
					-webkit-transition: all 0.3s ease-in-out;
					-moz-transition: all 0.3s ease-in-out;
					-o-transition: all 0.3s ease-in-out;
					-ms-transition: all 0.3s ease-in-out;
					z-index: 102;
					&::after{
						content: "";
						width: 0;
						height: 0;
						position: absolute;
						border-style: solid;
						border-width: 6px;
						border-color: transparent transparent transparent RGBA(55, 55, 55, 1);
						right: -12px;
						top: 16px;
					}
					img{
						width: 90px;
						height: 90px;
					}
					.text{
						font-weight: 600;
						padding-top: 10px;
						color: #FFFFFF;
					}
				}
			}
		}
	}
</style>
